import React from "react";
// import "../css/components/TableData.scss";
import { Row, Table, Button, Modal, Tag, Col } from "antd";
import { useState } from "react";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import CONSTANTS_INVEST from "../../../../config/constants_invest";
import {getObjectStatus} from "../../../../utils/common_invest";

const TableDataCommission = (props) => {
	const {
		data,
		loading,
		params,
		edit = () => { }
	} = props;

	const { t } = useTranslation();
	const texts = t;

	const columns = [
		{
			title: texts('no.'),
			dataIndex: "stt",
			key: "stt",
			align: "center",
			width: '46px',
		},
		{
			title: "Tên cấu hình",
			dataIndex: "code",
			key: "code",
			render: (_, record) => texts(getObjectStatus(CONSTANTS_INVEST.SYSTEM_CONFIG, record.code)?.label)
//            align: "center",
			// width: '160px',
		},
		{
			title: "Giá trị trước",
			dataIndex: "oldValue",
			key: "oldValue",
//            align: "center",
			// maxWidth: "160px"
		},
		{
			title: "Giá trị sau",
			dataIndex: "newValue",
			key: "newValue",
			align: "center",
		},
		{
			title: "Người thay đổi",
			dataIndex: "updatedByName",
			key: "updatedByName",
			align: "center"
		},
		{
			title: "Thời gian thay đổi",
			dataIndex: "updatedAt",
			key: "updatedAt",
			align: "center",
			render: (_, record) => moment(record?.updatedAt).format("HH:mm - DD/MM/YYYY")
		},
		// {
		// 	title: "Số tiền hoa hồng",
		// 	dataIndex: "fundType",
		// 	key: "fundType",
		// 	align: "center"
		// },
		// {
		//     title: "Hành động",
		//     dataIndex: "actions",
		//     key: "actions",
		//     align: "center",
		//     render: (_, record) => (
		//         <Row gutter={[8, 8]} justify={'center'}>
		//             {/*<Col>*/}
		//             {/*<Button*/}
		//             {/*onClick={() => edit(record)}*/}
		//             {/*type='primary'*/}
		//             {/*size='small'>*/}
		//             {/*Sửa*/}
		//             {/*</Button>*/}
		//             {/*</Col>*/}
		//             <Col>
		//                 <Button
		//                     onClick={() => edit(record)}
		//                     ghost
		//                     type='primary'
		//                     size='small'>
		//                     {texts('detail')}
		//                 </Button>
		//             </Col>
		//         </Row>
		//     ),
		// },
	];


	return (
		<Table
			bordered
			className="table-data"
			columns={columns}
			dataSource={data.map((d, index) => ({
				...d,
				stt: index + 1,
			}))}
			// expandable={expandable}
			loading={loading}
			pagination={false}
			rowKey={(record) => record?.id}
			size="small"
		/>
	);
};

export default TableDataCommission;
