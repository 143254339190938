import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {Pagination, Form, Row, Col, Input, Select, Button, Upload} from "antd";
import {AdminDetailHeader, CKEditorInvest, UploadImageInvest} from "../../../components";
import contentServices from "../../../services/invest/content";
import { useParams } from 'react-router-dom';
import CONSTANTS from "../../../config/constants_invest";
import {message} from "antd/es/index";
import {useTranslation} from "react-i18next";
import ReactQuillCustomize from "../../../components/ReactQuillCustomize";
import {PlusOutlined} from "@ant-design/icons";

const NewsDetail = (props) => {
    const {router} = props;
    const [form] = Form.useForm();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState({});
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [content, setContent] = useState('');
    const [actionKey, setActionKey] = useState('')
    const [callingAPI, setCallingAPI] = useState(false);
    const [thumbnail, setThumbnail] = useState();
    const [news, setNews] = useState({});
    const [params, setParams] = useState({
        page: 1,
        size: 10,
    });

    const { t } = useTranslation();
    const texts = t;

    useEffect(() => {
        fetchData();
    }, [params]);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        // let account_id = id;
        if(parseInt(id)){
            await contentServices.get_noti_promotion_detail(id)
                .then((response) => {
                    let news = response?.data || null;
                    if(news){
                        form.setFieldsValue({...news});
                        setContent(news?.content);
                        setNews(news);
                    }
                    // setCustomer(response?.data || {});
                    // setTotal(response?.page?.total_elements);
                })
                .catch(() => {
                    // setData([]);
                });
        }
        setLoading(false);
    };

    const update = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                const values = form.getFieldsValue();
                console.log('values', values);

                setCallingAPI(false);
            }
        })
    };

    const onChangeEditor = (value) => {
        setContent(value || " ");
    };

    const onUploadSuccess = (imageData) => {
        setThumbnail(imageData);
    };

    const handleSubmit = () => {
        form.validateFields().then(async (valid) => {
            if (valid) {
                setCallingAPI(true);
                const values = form.getFieldsValue();
                let dataPost = {
                    ...values,
	                // imageUrl: thumbnail || news?.imageUrl || '',
	                imageUrl: '',
	                content
                };
                console.log('dataPost', dataPost);
                if(parseInt(id)){
                    dataPost.id = parseInt(id);
                    contentServices.update_noti_promotion(dataPost).then(() => {
                        message.success(texts('update_success'));
                        // window.navigatePage('NEWS');
                    });
                }
                else{
                    contentServices.create_noti_promotion(dataPost).then(() => {
                        message.success(texts('create_success'));
                        window.navigatePage('NOTIFICATIONS');
                    });
                }
                setCallingAPI(false);
            }
        })
    };

	const deleteNoti = () => {
		contentServices.delete_noti_promotion(id).then(() => {
			message.success("Xoá thành công");
			window.navigatePage('NOTIFICATIONS');
		});
	}

    return (
        <div className="content-news">
            <AdminDetailHeader
                title={parseInt(id) ? router.label : texts('create')}
                actions={[
	                {
		                key: 'add',
		                label: 'Lưu',
		                type: 'primary',
		                // icon: <PlusOutlined />,
		                click: () => handleSubmit()
	                },
	                {
		                key: 'add',
		                label: 'Xoá',
		                type: 'primary',
		                danger: true,
		                hide: !parseInt(id),
		                // icon: <PlusOutlined />,
		                click: () => deleteNoti(),
		                confirm: true,
		                confirmDescription: "Xoá thông báo sẽ không thể hoàn tác, xác nhận xoá?",
		                title: "Xoá thông báo"
	                },
                ]}
            />

            <div className="content-wrapper" style={{marginTop: 24}}>
                <div className="page-content">

                    <div className="main-content">
                        <div className='extra-custome-info'>
                            <Form
                                form={form}
                                // onFinish={handleSubmit}
                                layout='vertical'
                            >
                                <Row justify={'left'} gutter={[8, 8]}>
                                    <Col span={24} lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={texts('title')}
                                            name={'title'}
                                            rules={[{
                                                required: true,
                                                message: 'Please enter Title'
                                            }]}
                                        >
                                            <Input
                                                placeholder="Enter title"
                                                disabled={callingAPI}
                                            />
                                        </Form.Item>
                                    </Col>

	                                <Col span={3} lg={6} sm={12} xs={24}>
		                                <Form.Item
			                                label={texts("category")}
			                                name="productCategory"
			                                rules={[{
				                                required: true,
				                                message: 'Bắt buộc chọn Danh mục'
			                                }]}
		                                >
			                                <Select
				                                className="w-100"
				                                placeholder={"Chọn danh mục"}
			                                >
				                                {CONSTANTS.NOTI_PRODUCT_CATEGORIES.map((type) => (
					                                <Select.Option
						                                value={type.value}
						                                key={type.value}
					                                >
						                                {type.name}
					                                </Select.Option>
				                                ))}
			                                </Select>
		                                </Form.Item>

	                                </Col>

	                                <Col span={3} lg={6} sm={12} xs={24}>
		                                <Form.Item
			                                label={'Phân loại'}
			                                name="type"
			                                rules={[{
				                                required: true,
				                                message: 'Bắt buộc chọn Phân loại'
			                                }]}
		                                >
			                                <Select
				                                className="w-100"
				                                placeholder={"Chọn phân loại"}
			                                >
				                                {CONSTANTS.NOTI_TYPES.map((type) => (
					                                <Select.Option
						                                value={type.value}
						                                key={type.value}
					                                >
						                                {type.name}
					                                </Select.Option>
				                                ))}
			                                </Select>
		                                </Form.Item>
	                                </Col>


                                    {/*<Col span={2} lg={2} sm={24} xs={24}/>*/}
                                    {/*<Col span={4} lg={4} sm={24} xs={24}>*/}
                                    {/*    <Form.Item*/}
                                    {/*        name={'status'}*/}
                                    {/*        label={texts('status')}*/}
                                    {/*        rules={[{*/}
                                    {/*            required: true,*/}
                                    {/*            message: 'Please select status'*/}
                                    {/*        }]}*/}
                                    {/*    >*/}
                                    {/*        <Select*/}
                                    {/*            className="w-100"*/}
                                    {/*            disabled={callingAPI}*/}
                                    {/*        >*/}
                                    {/*            {CONSTANTS.NEWS_DISPLAY_STATUS.map((status) => (*/}
                                    {/*                <Select.Option*/}
                                    {/*                    value={status.value}*/}
                                    {/*                    key={status.value}*/}
                                    {/*                >*/}
                                    {/*                    {texts(status.label)}*/}
                                    {/*                </Select.Option>*/}
                                    {/*            ))}*/}
                                    {/*        </Select>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}
                                    <Col span={24} lg={24} sm={24} xs={24}>
                                        <Form.Item
                                            label={texts('short_description')}
                                            name={'description'}
                                            rules={[{
                                                required: true,
                                                message: 'Please enter short description'
                                            }]}
                                        >
                                            <Input.TextArea
                                                rows={4}
                                                placeholder="Enter short description"
                                                disabled={callingAPI}
                                            />
                                        </Form.Item>
                                    </Col>

                                    {/*<Col span={2} lg={2} sm={24} xs={24}/>*/}
                                    {/*<Col span={6} lg={6} sm={24} xs={24}>*/}
                                    {/*    <Form.Item*/}
                                    {/*        label={texts("image")}*/}
                                    {/*        name="imageUrl"*/}
                                    {/*        rules={[]}>*/}
                                    {/*        <UploadImageInvest*/}
                                    {/*            onUploadSuccess={onUploadSuccess}*/}
                                    {/*            defaultImg={news?.linkApp}*/}
                                    {/*        />*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}



                                    <Col span={24} lg={24} sm={24} xs={24}>
	                                    <ReactQuillCustomize value={content} setValue={setContent} />

                                        {/*<Form.Item*/}
                                        {/*    label={texts("content")}*/}
                                        {/*    name="content"*/}
                                        {/*    rules={[{*/}
                                        {/*        required: true,*/}
                                        {/*        message: 'Please enter content'*/}
                                        {/*    }]}*/}
                                        {/*>*/}
                                        {/*    /!*{(!parseInt(id) || content) && <CKEditorInvest initValue={content} onChange={onChangeEditor} />}*!/*/}
                                        {/*    /!*{(!parseInt(id) || content) && <ReactQuillCustomize value={content} setValue={setContent} />}*!/*/}
	                                    {/*    <ReactQuillCustomize value={content} setValue={setContent} />*/}
                                        {/*</Form.Item>*/}
                                    </Col>
                                </Row>


                                <Row gutter={[0, 0]} className="detail-footer">
                                    <Col>
                                        <Button
                                            type="primary"
                                            disabled={loading || (callingAPI && actionKey !== 'updating')}
                                            loading={callingAPI && actionKey === 'updating'}
                                            onClick={handleSubmit}
                                        >
                                            {parseInt(id) ? texts('save') : texts('create')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default NewsDetail;
