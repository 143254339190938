import React, {useState} from "react";
import "../css/components/Filter.scss";
import {Row, Col, Input, Select, DatePicker} from "antd";

import {SearchOutlined} from "@ant-design/icons";
import CONSTANTS from "../../../../../config/constants_invest";
import {QuickSelectDate, QuickSelectQuarter} from "../../../../../components";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);
import moment from "moment";
import {useTranslation} from "react-i18next";


const Filter = (props) => {
	const { t } = useTranslation();
	const texts = t;

	const {
		params = {}, setParams = () => {}
	} = props;

	const onDateChange = (dates) => {
		setParams({
			...params,
			dates
		})
	}

	return (
		<Row className="filter" gutter={[8, 8]}>
			<Col span={3} lg={6} sm={12} xs={24}>
				<Input
					prefix={<SearchOutlined/>}
					value={params.customerName}
					placeholder="Tìm khách hàng"
					onChange={(e) => setParams({...params, customerName: e.target.value})}
				/>
			</Col>

			<Col span={3} lg={3} sm={6} xs={24}>
				<Select
					placeholder={"Trạng thái thanh toán"}
					value={params?.paymentStatus || null}
					onChange={(v) => setParams({...params, paymentStatus: v})}
					showSearch={true}
					optionFilterProp="children"
					className="w-full"
					filterOption={(input, option) =>
						`${option.children}`.toLowerCase().includes(input.toLowerCase())
					}
				>
					{CONSTANTS.INVITATION_COMMISSION_PAYMENT_STATUS.map((it) => (
						<Select.Option value={it.value} key={it.value}>
							{texts(it?.label)}
						</Select.Option>
					))}
				</Select>
			</Col>

			<Col span={3} lg={3} sm={6} xs={24}>
				<Select
					placeholder={"Loại giao dịch"}
					value={params?.transactionType || null}
					onChange={(v) => setParams({...params, transactionType: v})}
					showSearch={true}
					optionFilterProp="children"
					className="w-full"
					filterOption={(input, option) =>
						`${option.children}`.toLowerCase().includes(input.toLowerCase())
					}
				>
					{CONSTANTS.INVITATION_COMMISSION_TYPE.map((it) => (
						<Select.Option value={it.value} key={it.value}>
							{texts(it?.label)}
						</Select.Option>
					))}
				</Select>
			</Col>

			<QuickSelectDate
				params={params}
				onChange={onDateChange}
				additionDates={[
					{
						name: `Quý này`,
						value: 10,
						label: "",
						from_date: dayjs().startOf("quarter"),
						to_date: dayjs().endOf("quarter"),
					},
					{
						name: `Quý trước`,
						value: 11,
						label: "",
						from_date: dayjs().subtract(1, "quarter").startOf("quarter"),
						to_date: dayjs().subtract(1, "quarter").endOf("quarter"),
					},
					{
						name: `Quý ${dayjs().subtract(2, "quarter").quarter()}/${dayjs().subtract(2, "quarter").startOf("quarter").format("YYYY")}`,
						value: 12,
						label: "",
						from_date: dayjs().subtract(2, "quarter").startOf("quarter"),
						to_date: dayjs().subtract(2, "quarter").endOf("quarter"),
					},
					{
						name: `Quý ${dayjs().subtract(3, "quarter").quarter()}/${dayjs().subtract(3, "quarter").startOf("quarter").format("YYYY")}`,
						value: 13,
						label: "",
						from_date: dayjs().subtract(3, "quarter").startOf("quarter"),
						to_date: dayjs().subtract(3, "quarter").endOf("quarter"),
					},
				]}
			/>

		</Row>
	);
};

export default Filter;
