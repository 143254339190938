import React, {useState, useEffect} from "react";
import "./css/index.scss";
import {AdminDetailHeader, CKEditorInvest} from "../../../components";
import {Pagination, Row, Col, Button, Tabs, message} from "antd";
import contentService from "../../../services/invest/content";
import ReactQuillCustomize from "../../../components/ReactQuillCustomize";
import CONSTANTS_INVEST from "../../../config/constants_invest";

const Policy = (props) => {
    const {router} = props;
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);
    const [generalRule, setGeneralRule] = useState();
    const [privacyRule, setPrivacyRule] = useState();
    const [invitationRule, setInvitationRule] = useState();

    // const [content, setContent] = useState("");
    const [data, setData] = useState();
    // const [total, setTotal] = useState(10);
    const [callingAPI, setCallingAPI] = useState(false)
    const [actionKey, setActionKey] = useState('')
    const [params, setParams] = useState({
        page: 1,
        size: 20,
    });

    useEffect(() => {
        fetchData();
    }, []);

    const handleChangePage = (page, size) => {
        setParams({
            ...params,
            page,
            size,
        });
    };

    const fetchData = async () => {
        setLoading(true);

        const res1 = await contentService.get_policy({type: CONSTANTS_INVEST.RULE_TYPE.GENERAL});
        const res2 = await contentService.get_policy({type: CONSTANTS_INVEST.RULE_TYPE.PRIVACY});
        const res3 = await contentService.get_policy({type: CONSTANTS_INVEST.RULE_TYPE.INVITATION});
	    setGeneralRule(res1?.data?.[0]);
	    setPrivacyRule(res2?.data?.[0]);
	    setInvitationRule(res3?.data?.[0]);

        setLoading(false);
    };


	const setRule = (value) => {
		console.log("setRule generalRule", generalRule);
		switch (tab){
			case 0:
				setGeneralRule({...generalRule, shortDesc: value})
				break;
			case 1:
				setPrivacyRule({...privacyRule, shortDesc: value})
				break;
			case 2:
				setInvitationRule({...invitationRule, shortDesc: value})
				break;
		}
	}

    const update = () => {
		let rule;
		switch (tab){
			case 0:
				rule = generalRule;
				// shortDesc = generalRule;
				// type = CONSTANTS_INVEST.RULE_TYPE.GENERAL;
				break;
			case 1:
				rule = privacyRule;
				// shortDesc = privacyRule;
				// type = CONSTANTS_INVEST.RULE_TYPE.PRIVACY;
				break;
			case 2:
				rule = invitationRule;
				// shortDesc = invitationRule;
				// type = CONSTANTS_INVEST.RULE_TYPE.INVITATION;
				break;
		}
        setActionKey('updating');
        setCallingAPI(true);
	    console.log("update generalRule", rule);
		// const data = {
		// 	id: rule.id,
		// 	type: rule.type,
		// 	shortDesc: rule.shortDesc,
		// 	nameBank: "",
		// 	userAccBank: "",
		// 	numberBank: "",
		// 	codeStruct: "",
		// 	fixValue: 0,
		// 	fixValueUnit: 0,
		// 	displayImage: null
		// }
        contentService.update_policy(rule).then(() => {
            message.success("Cập nhật thành công!");
            setCallingAPI(false);
        }).catch((error) => {
            setCallingAPI(false);
            message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        });
    };

	const onChangeTab = (key) => {
		console.log(key);
		setTab(key)
	};

	const items = [
		{
			key: 0,
			label: 'Các điều khoản chung',
		},
		{
			key: 1,
			label: 'Các điều khoản bảo mật',
		},
		{
			key: 2,
			label: 'Người giới thiệu và hoa hồng',
		},
	];


    return (
        <div className="content-policy">
            <AdminDetailHeader
                title={router.label}
            />

	        <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />

	        {!loading && tab === 0 ? <ReactQuillCustomize value={generalRule?.shortDesc} setValue={setRule}/> : null}
	        {!loading && tab === 1 ? <ReactQuillCustomize value={privacyRule?.shortDesc} setValue={setRule}/> : null}
	        {!loading && tab === 2 ? <ReactQuillCustomize value={invitationRule?.shortDesc} setValue={setRule}/> : null}


	        <Button
		        type="primary"
		        disabled={loading || (callingAPI && actionKey !== 'updating')}
		        loading={callingAPI && actionKey === 'updating'}
		        onClick={() => update()}
		        style={{marginTop: 24}}
	        >
		        Lưu
	        </Button>
        </div>
    );
};

export default Policy;
