import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Input,
	Modal,
	Form,
	Select,
	message,
	Card
} from 'antd';

import {
} from "@ant-design/icons";
import moment from 'moment';
import CONSTANTS from "../../../../../../config/constants";
import { PROJECT_RULES } from "../../../../../../config/rules";
// import productServices from '../../../../../services/finblue/product';
import customerService from "../../../../../../services/invest/customer";
import {displayInvestProfitCapitalDeal, formatMoneyCurrency} from "../../../../../../utils/common";
import {useTranslation} from "react-i18next";
import {getObjectStatus, renderStatus} from "../../../../../../utils/common_invest";
import CONSTANTS_INVEST from "../../../../../../config/constants_invest";

const ModalTransactionDetail = (props) => {
	const [customer, setCustomer] = useState({});
	const [form] = Form.useForm();
	const {
		transaction = null,
		visible = false,
		onClose = () => {},
		reload = () => {}
	} = props;

	const { t } = useTranslation();
	const texts = t;

	useEffect(() => {
		// fetchData();
		// if (product) {
		//     form.setFieldsValue({
		//         name: product.name,
		//         active: product.active
		//     })
		// } else {
		//     form.setFieldsValue({
		//         name: null,
		//         active: true
		//     })
		// }
	}, [transaction])

	const [callingAPI, setCallingAPI] = useState(false);

	// const fetchData = async () => {
	// 	await customerService.customer_detail({
	// 		account_id: transaction?.userId
	// 	})
	// 		.then((response) => {
	// 			setCustomer(response?.data || {});
	// 		})
	// 		.catch(() => {
	// 			setCustomer({});
	// 		});
	// };

	const handleSubmit = () => {
		form.validateFields().then(async (valid) => {
			if (valid) {
				setCallingAPI(true);
				const values = form.getFieldsValue();
				if (transaction) {
					await handleUpdate({
						id: transaction.id,
						...values,
					})
				} else {
					await handleCreate(values)
				}
				setCallingAPI(false);
			}
		})
	}

	const handleCreate = async (data) => {
		await productServices.create(data).then(() => {
			message.success('Thêm gói sản phẩm thành công')
			reload();
			onClose();
		}).catch((error) => {
			message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
		})
	}

	const handleUpdate = async (data) => {
		await productServices.update(data).then(() => {
			message.success('Cập nhật gói sản phẩm thành công')
			reload();
			onClose();
		}).catch((error) => {
			message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
		})
	}

	console.log(getObjectStatus(CONSTANTS_INVEST.INVITATION_COMMISSION_PAYMENT_METHOD, transaction?.paymentMethod)?.label);
	console.log(texts);
	console.log(texts?.[getObjectStatus(CONSTANTS_INVEST.INVITATION_COMMISSION_PAYMENT_METHOD, transaction?.paymentMethod)?.label]);

	return (
		<Modal
			title={"Chi tiết giao dịch"}
			width={500}
			open={visible}
			footer={null}
			// onOk={() => handleSubmit()}
			onCancel={() => onClose()}
			// okButtonProps={{
			//     loading: callingAPI
			// }}
			// cancelButtonProps={{
			//     disabled: callingAPI
			// }}
			// okText='Lưu'
			// okButtonProps={{ disabled: true, className: "modal-footer-hiden-button" }}
			// cancelText='Đóng'
		>
			<table className="table-transaction-detail">
				<tr>
					<td className="transaction-detail-col-1">Người nhận tiền (người giới thiệu)</td>
					<td className="transaction-detail-col-2">{transaction?.referralAccountName}</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">Người được giới thiệu</td>
					<td className="transaction-detail-col-2">{transaction?.accountName}</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">Loại giao dịch	</td>
					<td className="transaction-detail-col-2">{transaction?.transactionType}</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">{texts('amount')}</td>
					<td className="transaction-detail-col-2">{formatMoneyCurrency(transaction?.transactionAmount)}</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">% hoa hồng</td>
					<td className="transaction-detail-col-2">{transaction?.commissionPercent*100} %</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">Số tiền hoa hồng</td>
					<td className="transaction-detail-col-2">{formatMoneyCurrency(transaction?.commission)}</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">{texts('transaction_date')}</td>
					<td className="transaction-detail-col-2">{moment(transaction?.createdDate).format('HH:mm - DD/MM/YYYY')}</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">Trạng thái chi trả</td>
					<td className="transaction-detail-col-2">
						{renderStatus(transaction.paymentStatus, texts)}
					</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">Ngày chi trả</td>
					<td className="transaction-detail-col-2">
						{transaction?.paymentTime ? moment(transaction?.paymentTime).format('HH:mm - DD/MM/YYYY') : "-"}
					</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">Phương thức chi trả</td>
					<td className="transaction-detail-col-2">
						{texts(getObjectStatus(CONSTANTS_INVEST.INVITATION_COMMISSION_PAYMENT_METHOD, transaction?.paymentMethod)?.label)}
					</td>
				</tr>
				<tr>
					<td className="transaction-detail-col-1">Người chi trả</td>
					<td className="transaction-detail-col-2">
						{transaction?.paymentBy}
					</td>
				</tr>

			</table>


		</Modal>

	);
}

export default ModalTransactionDetail;