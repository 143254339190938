const BACKEND_URL = process.env.REACT_APP_INVEST_API_URL;
const FINBLUE_URL = process.env.REACT_APP_FINBLUE_API_URL;
const BACKEND_URL_MEDIA = process.env.REACT_APP_INVEST_MEDIA_API_URL;

export default {
    CUSTOMERS: `${BACKEND_URL}/cms/get-list-customer`,
    CUSTOMER_DETAIL: `${BACKEND_URL}/cms/get-customer-info`,
    GET_FA_BY_ACC_ID: `${BACKEND_URL}/cms/get-fmarket-account`,
    GET_ACCOUNT_KYC_ERROR: `${BACKEND_URL}/cms/get-account-kyc-error`,
    VERIFY_KYC: `${BACKEND_URL}/cms/verify-kyc`,

    BANKS: `${BACKEND_URL}/cms/get-account-bank-info`,

    GET_LIST_BUSINESS_STAFF: `${BACKEND_URL}/cms/get-list-business-staff`,
    GET_INFO_BUSINESS_STAFF: `${BACKEND_URL}/cms/get-info-business-staff`,
    GET_LIST_CUSTOMER_BY_STAFF: `${BACKEND_URL}/cms/get-list-customer-by-staff`,
    SALESMAN_UPDATE: `${BACKEND_URL}/cms/update-info-business-staff`,
    GENERATE_SALE_ID: `${BACKEND_URL}/generate_sale_id`,
    ASSIGN_CUSTOMER_TO_SALES: `${BACKEND_URL}/cms/asign-customer-business-staff`,

    SAVINGS_PRODUCT_LIST: `${BACKEND_URL}/cms/get-list-product`,
    SAVINGS_PRODUCT_UPDATE: `${BACKEND_URL}/cms/update-product`,
    LIST_INTERMEDIATE_SETTLED: `${BACKEND_URL}/list_intermediate_settled`,
    SETTLE_FIRM_BANKING: `${BACKEND_URL}/get-payment-force-settle-request-not-firm-banking`,
    PAYMENT_SETTLE_FIRM_BANKING: `${BACKEND_URL}/payment-force-settle-request-not-firm-banking`,
    GET_FORCE_SETTLE_DETAIL: `${BACKEND_URL}/payment/get_force_settle_detail`,
    APPROVE_REJECT_FORCE_SETTLE: `${BACKEND_URL}/payment/approve_reject_force_settle`,
    UPDATE_TRANSACTION_PROCESSING_STATUS: `${BACKEND_URL}/cms/multi-update-processing-status-payment-transactions`,
    GET_SAVING_CONTRACT: `${BACKEND_URL}/cms/get-saving-contract`,

    BALANCE_LOGS: `${BACKEND_URL}/cms/get-log-balance`,
    BALANCE_LOGS_DETAIL: `${BACKEND_URL}/cms/get-log-balance/get-by-id`,
    SAVINGS_TRANSACTIONS: `${BACKEND_URL}/cms/get-log-savings-package`,
    SAVINGS_ORDERS: `${BACKEND_URL}/cms/get-log-savings-order`,

    GET_BALANCE_CHANGE_REQUEST: `${BACKEND_URL}/cms/get-balance-change-request`,
    GET_LOG_CALL_PARTNER: `${BACKEND_URL}/cms/log-call-partner/get`,
    UPDATE_LOG_CALL_PARTNER: `${BACKEND_URL}/cms/log-call-partner/update`,

    MUTUAL_FUNDS_DATA: `${BACKEND_URL}/cms/get-all-mutal-funds-data`,
    UPDATE_MUTUAL_FUNDS: `${BACKEND_URL}/cms/update-mutual-funds`,

    FMARKET_ACCOUNTS: `${BACKEND_URL}/cms/get-list-fmarket-account`,
    FMARKET_ORDERS: `${BACKEND_URL}/cms/get-list-order`,
    FMARKET_TRANSACTIONS: `${BACKEND_URL}/cms/get-list-transaction`,
    FMARKET_TRADING_CONTRACT: `${BACKEND_URL}/cms/get-trading-contract`,
    FMARKET_TRADING_CONTRACT_SIGN: `${BACKEND_URL}/cms/log_image_sign/account`,

    GET_COMMUNITY_INFO: `${BACKEND_URL}/cms/community_info/get`,
    CREATE_COMMUNITY_INFO: `${BACKEND_URL}/cms/community_info/create`,
    UPDATE_COMMUNITY_INFO: `${BACKEND_URL}/cms/community_info/update`,
    GET_MANAGE_INFO: `${BACKEND_URL}/cms/get-manage-info`,
    UPDATE_MANAGE_INFO: `${BACKEND_URL}/cms/update-manage-info`,
    GET_QUESTION_INFO: `${BACKEND_URL}/cms/question_info/get`,
    UPDATE_QUESTION_INFO: `${BACKEND_URL}/cms/question_info/update`,
    CREATE_QUESTION_INFO: `${BACKEND_URL}/cms/question_info/create`,
    DELETE_QUESTION_INFO: `${BACKEND_URL}/cms/question_info/delete`,

    SUPPORT_CENTER_GET: `${BACKEND_URL}/cms/support-center`,
    SUPPORT_CENTER_POST: `${BACKEND_URL}/cms/support-center`,
    SUPPORT_CENTER_UPDATE: `${BACKEND_URL}/cms/support-center/update`,
    SUPPORT_CENTER_DELETE: `${BACKEND_URL}/cms/support-center/delete`,

    NOTI_PROMOTION: `${BACKEND_URL}/cms/notification-promotion`,
    NOTI_PROMOTION_DETAIL: `${BACKEND_URL}/cms/notification-promotion/detail`,

    UPLOAD_FILE: `${BACKEND_URL_MEDIA}/invest-cms-service/cms/upload-image`,
    UPLOAD_FILE_OTHER: `${BACKEND_URL}/cms/upload-other-file`,

    DASH_SUM_CUSTOMER: `${BACKEND_URL}/dashboard/sum-customer`,
    DASH_CUT_OFF_CUSTOMER: `${BACKEND_URL}/dashboard/cut-off-customer`,
    DASH_TOTAL_AMOUNT_SAVINGS: `${BACKEND_URL}/dashboard/total-amount-savings`,
    DASH_CUT_OFF_SAVING_ORDER: `${BACKEND_URL}/dashboard/cut-off-saving-order`,
    DASH_SUM_CUSTOMER_FUNDS: `${BACKEND_URL}/dashboard/sum-customer-funds`,
    DASH_SUM_AMOUNT_FUNDS: `${BACKEND_URL}/dashboard/sum-amount-funds`,
    DASH_CUT_OFF_TRANSACTION_FUNDS: `${BACKEND_URL}/dashboard/cut-off-transaction-funds`,

    REPORT_SAVING_TRANSACTIONS_CUSTOMER: `${BACKEND_URL}/cms/report-payment-transactions-customer`,
    REPORT_FMARKET_TRANSACTIONS_CUSTOMER: `${BACKEND_URL}/cms/report-fmarket-transactions-customer`,

    INVITATION_COMMISSION: `${BACKEND_URL}/cms/get-list-commissions`,
    INVITATION_COMMISSION_UPDATE: `${BACKEND_URL}/cms/update-commissions`,
    GET_SYSTEM_CONFIG: `${BACKEND_URL}/cms/get-system-config`,
    SYSTEM_CONFIG_UPDATE: `${BACKEND_URL}/cms/update-system-config`,
    GET_LOG_UPDATE_SYSTEM_CONFIG: `${BACKEND_URL}/cms/get-log-update-system-config`,
};
