import React, {useState, useEffect} from "react";
// import "./css/index.scss";
import {Col, Pagination, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {AdminHeader} from "../../../../components";

import Filter from "./components/Filter";
import TableData from "./components/TableData";
import ModalDetail from "./components/modals/ModalDetail";
// import fmkService from "../../../services/invest/fmarket";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import invitationService from "../../../../services/invest/invitationService";
import {removeNullUndefined} from "../../../../utils/common_invest";
import {formatMoneyCurrency} from "../../../../utils/common";

const InvitationCommission = (props) => {
	const {router} = props;
	const [loading, setLoading] = useState(false);
	const [formVisible, setFormVisible] = useState(false);
	const [selectedTransaction, setSelectedTransaction] = useState(null);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [totalCommission, setTotalCommission] = useState(0);
	const [params, setParams] = useState({
		page: 1,
		size: 20,
		paymentMethod: "",
		paymentStatus: "",
		transactionType: "",
		customerName: "",
		dates: [
			dayjs().startOf('day'),
			dayjs().endOf('day'),
		],
	});

	const { t } = useTranslation();
	const texts = t;

	useEffect(() => {
		fetchData();
	}, [params]);

	const handleChangePage = (page, size) => {
		setParams({
			...params,
			page,
			size,
		});
	};

	const fetchData = async () => {
		setLoading(true);
		let newParams = removeNullUndefined({
			page: params.page - 1,
			size: params.size,
			fromDate: dayjs(params.dates[0]).startOf('day').format('x'),
			toDate: dayjs(params.dates[1]).endOf('day').format('x'),
			paymentMethod: params.paymentMethod,
			paymentStatus: params.paymentStatus,
			transactionType: params.transactionType,
			customerName: params.customerName,
		}, [null, undefined, ""])
		await invitationService.getInvitationCommission(newParams)
			.then((response) => {
				setData(response?.data || []);
				setTotal(response?.page?.total_elements || 0);
				setTotalCommission(response?.data.reduce(
					(a, b) => a + (b?.commission || 0),
					0,
				));
			})
			.catch(() => {
				setData([]);
			});
		setLoading(false);
	};

	const confirmPaymentSuccess = () => {
		fetchData();
	}

	const handleOpenFormModal = (transaction = null) => {
		setSelectedTransaction(transaction);
		setFormVisible(true);
	};

	return (
		<div className="cms-users">
			<AdminHeader
				title={router.label}
				// total={total}
			/>

			<Filter params={params} setParams={setParams}/>

			<Row gutter={[12, 0]} style={{marginTop: 16}}>
				<Col span={6} lg={6} sm={12} xs={24}>
					<div className="dashboard-card dashboard-card-savings-transactions-1">
						<div className="dashboard-card-content" style={{padding: 10, marginLeft: 0}}>
							<div className="dashboard-card-name">Tổng giao dịch</div>
							<div className="dashboard-card-value">{total}</div>
						</div>
					</div>
				</Col>
				<Col span={6} lg={6} sm={12} xs={24}>
					<div className="dashboard-card dashboard-card-savings-transactions-2">
						<div className="dashboard-card-content" style={{padding: 10, marginLeft: 0}}>
							<div className="dashboard-card-name">Tổng tiền hoa hồng</div>
							<div className="dashboard-card-value">{formatMoneyCurrency(totalCommission)}</div>
						</div>
					</div>
				</Col>
			</Row>

			<div style={{marginTop: 40}}></div>
			<AdminHeader
				title={"Thông tin chi tiết"}
				// total={total}
			/>

			<TableData
				loading={loading}
				data={data}
				params={params}
				showDetail={(v) => handleOpenFormModal(v)}
				confirmPaymentSuccess={confirmPaymentSuccess}
			/>

			<Pagination
				className="pagination"
				current={params.page}
				pageSize={params.size}
				total={total}
				showSizeChanger
				showQuickJumper
				showTotal={(total) => `${texts('total')} ${total}`}
				onChange={handleChangePage}
			/>

			{
				formVisible ? <ModalDetail
					visible={formVisible}
					transaction={selectedTransaction}
					onClose={() => setFormVisible(false)}
					reload={() => fetchData()}
				/> : null
			}
		</div>
	);
};

export default InvitationCommission;
