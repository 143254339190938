import React, {useState, useEffect} from "react";
import {Row, Col, Input, Select, DatePicker} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import moment from 'moment';
import dayjs from 'dayjs';
import CONSTANTS from "../../config/constants";
import './style.scss';
import {useTranslation} from "react-i18next";

const DEFAULT_DATE_SELECTS = [
    {
		name: "Today",
	    value: 0,
	    label: "today",
	    from_date: dayjs(),
	    to_date: dayjs()
    },
    {
		name: "Yesterday",
	    value: 1,
	    label: "yesterday",
	    from_date: dayjs().subtract(1, 'days'),
	    to_date: dayjs().subtract(1, 'days')
    },
    {
		name: "Last 7 days",
	    value: 2,
	    label: "last_7_days",
	    from_date: dayjs().subtract(6, 'days'),
	    to_date: dayjs()
    },
    {
		name: "Last 30 days",
	    value: 3,
	    label: "last_30_days",
	    from_date: dayjs().subtract(30, 'days'),
	    to_date: dayjs()
    },
    {
		name: "This month",
	    value: 4,
	    label: "this_month",
	    from_date: dayjs().startOf('month'),
	    to_date: dayjs().endOf('month')
    },
    {
		name: "Last month",
	    value: 5,
	    label: "last_month",
	    from_date: dayjs().subtract(1,'months').startOf('month'),
	    to_date: dayjs().subtract(1,'months').endOf('month')
    },
];

const QuickSelectDate = (props) => {
    const {
        params = {},
        onChange = () => {},
	    additionDates = []
    } = props;

    const { t } = useTranslation();
    const texts = t;

	const DATE_SELECTS = DEFAULT_DATE_SELECTS.concat(additionDates);

    const [dates, setDates] = useState(params?.dates || []);
    const [selectValue, setSelectValue] = useState();

    useEffect(() => {
        mapDatesToSelect(dates);
    }, []);

    const mapDatesToSelect = (dates) => {
	    const _start = dayjs(dates?.[0]).format(CONSTANTS.DATE_FORMAT);
	    const _end = dayjs(dates?.[1]).format(CONSTANTS.DATE_FORMAT);
		const findDate = DATE_SELECTS.find(e => dayjs(e.from_date).format(CONSTANTS.DATE_FORMAT)  === _start && dayjs(e.to_date).format(CONSTANTS.DATE_FORMAT) === _end);
		if(findDate){
			setSelectValue(findDate.value);
		}
		else{
			setSelectValue();
		}
		// return;

	    // let start = dayjs(dates?.[0]).format('DD/MM/YYYY');
	    // let end = dayjs(dates?.[1]).format('DD/MM/YYYY');
	    // let today = dayjs().format('DD/MM/YYYY');
	    // let yesterday = dayjs().subtract(1, 'day').format('DD/MM/YYYY');
	    // let last7days = dayjs().subtract(7, 'day').format('DD/MM/YYYY');
	    // let last30days = dayjs().subtract(30, 'day').format('DD/MM/YYYY');
	    // let startOfMonth = dayjs().startOf('month').format('DD/MM/YYYY');
	    // let endOfMonth = dayjs().endOf('month').format('DD/MM/YYYY');
	    // let startOfLastMonth = dayjs().subtract(1, 'month').startOf('month').format('DD/MM/YYYY');
	    // let endOfLastMonth = dayjs().subtract(1, 'month').endOf('month').format('DD/MM/YYYY');

        // console.log('dates', dates);
        // console.log('start', start);
        // console.log('end', end);
        // console.log('today', today);
        // console.log('yesterday', yesterday);
        // console.log('last7days', last7days);
        // console.log('last30days', last30days);
        // console.log('startOfMonth', startOfMonth);
        // console.log('start = startOfMonth', start === startOfMonth);
        // console.log('end = endOfMonth', end === endOfMonth);
        // console.log('startOfLastMonth', startOfLastMonth);
        // console.log('endOfLastMonth', endOfLastMonth);

        // if(start === today && end === today){
        //     setSelectValue(DATE_SELECTS[0]?.value);
        // }
        // else if(start === yesterday && end === yesterday){
        //     setSelectValue(DATE_SELECTS[1]?.value);
        // }
        // else if(start === last7days && end === today){
        //     setSelectValue(DATE_SELECTS[2]?.value);
        // }
        // else if(start === last30days && end === today){
        //     setSelectValue(DATE_SELECTS[3]?.value);
        // }
        // else if(start === startOfMonth && end === endOfMonth){
        //     setSelectValue(DATE_SELECTS[4]?.value);
        // }
        // else if(start === startOfLastMonth && end === endOfLastMonth){
        //     setSelectValue(DATE_SELECTS[5]?.value);
        // }
        // else{
        //     setSelectValue();
        // }
    };

    const actionSelect = (value) => {
        let {from_date, to_date} = selectDate(value);
        let dates = [
            dayjs(from_date),
            dayjs(to_date),
        ];
        setDates(dates);
        setSelectValue(value);
        onChange(dates);
    };

    const dateChange = (dates) => {
        setDates(dates);
        mapDatesToSelect(dates);
        onChange?.(dates);
    };

    const selectDate = (value) => {
		const findDate = DATE_SELECTS.find(e => e.value === value);
		console.log("selectDate findDate", findDate);
		if(findDate){
			return findDate;
		}
		else{
			return {from_date: "", to_date: ""}
		}


	    // let from_date = "";
	    // let to_date = "";
        // switch (value){
        //     case 0: //hom nay
        //         from_date = moment();
        //         to_date = from_date;
        //         break;
        //     case 1: //hom qua
        //         from_date = moment().subtract(1, 'days');
        //         to_date = moment().subtract(1, 'days');
        //         break;
        //     case 2: //7 ngày qua
        //         from_date = moment().subtract(6, 'days');
        //         to_date = moment();
        //         break;
        //     case 3: //30 ngày qua
        //         from_date = moment().subtract(29, 'days');
        //         to_date = moment();
        //         break;
        //     // case 5: //tuan nay
        //     //     if(moment().format('dddd') === 'Sunday'){
        //     //         from_date = moment().day(-6);
        //     //         to_date = moment();
        //     //     }
        //     //     else{
        //     //         from_date = moment().day(1);
        //     //         to_date = moment();
        //     //     }
        //     //     break;
        //     // case 6: //tuan truoc
        //     //     if(moment().format('dddd') === 'Sunday'){
        //     //         from_date = moment().day(-13);
        //     //         to_date = moment().day(-7);
        //     //         // from_date = moment().day(-6).format('DD/MM/YYYY');
        //     //     }
        //     //     else{
        //     //         from_date = moment().day(-6);
        //     //         to_date = moment().day(0);
        //     //     }
        //     //     break;
        //     case 4: //tháng này
        //         from_date = moment().startOf('month');
        //         to_date = moment().endOf('month');
        //         break;
        //     case 5: //tháng trước
        //         from_date = moment().subtract(1,'months').startOf('month');
        //         to_date = moment().subtract(1,'months').endOf('month');
        //         break;
        //     // case 9: //năm nay
        //     //     from_date = moment().startOf('year');
        //     //     to_date = moment().endOf('year');
        //     //     break;
        //     // case 10: //năm trước
        //     //     from_date = moment().subtract(1,'year').startOf('year');
        //     //     to_date = moment().subtract(1,'year').endOf('year');
        //     //     break;
        // }
        // // this.setState({from_date, to_date, index_date: index});
        // // this.onFilter(from_date, to_date, index);
        // return {from_date, to_date}
    };

    return (
        <>
            <Col span={6} lg={6} sm={12} xs={24}>
                <DatePicker.RangePicker
                    className="filter-date-picker"
                    format={CONSTANTS.DATE_FORMAT}
                    value={dates}
                    onChange={(v) => dateChange(v)}
                />
            </Col>
            <Col span={6} lg={6} sm={12} xs={24}>
                <Select
                    placeholder={"Select options"}
                    value={selectValue}
                    onChange={(value) => actionSelect(value)}
                    showSearch={true}
                    optionFilterProp="children"
                    className="w-full"
                    filterOption={(input, option) =>
                        `${option.children}`.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {DATE_SELECTS.map((option) => (
                        <Select.Option value={option.value} key={option.value}>
                            {texts?.(option?.label) || option?.name}
                        </Select.Option>
                    ))}
                </Select>
            </Col>
        </>
    );
};

export default QuickSelectDate;