import React from "react";
import "../css/components/TableData.scss";
import {Row, Table, Button, Modal, Tag, Col, message} from "antd";
import { useState } from "react";
import {useTranslation} from "react-i18next";
import {formatMoneyCurrency} from "../../../../../utils/common";
import {getAccountHref, getObjectStatus, renderStatus} from "../../../../../utils/common_invest";
import CONSTANTS from "../../../../../config/constants_invest";
import moment from "moment";
import invitationService from "../../../../../services/invest/invitationService";

const TableData = (props) => {
    const {
        data,
        loading,
        params,
	    showDetail = () => { },
	    confirmPaymentSuccess = () => {}
    } = props;

    const { t } = useTranslation();
    const texts = t;

    const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [totalAmountSelect, setTotalAmountSelect] = useState(0);
	const [loadingState, setLoadingState] = useState(false);

	const onSelectChange = (newSelectedRowKeys, newSelectedRow) => {
		let _totalAmountSelect = 0;
		newSelectedRow.forEach(e => _totalAmountSelect += (e?.transactionAmount || 0));
		setTotalAmountSelect(_totalAmountSelect);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record) => ({
			disabled: record.paymentStatus === 'SUCCESS'
		}),
	};

	const hasSelected = selectedRowKeys.length > 0;

	const onConfirmTransferred = async() => {
		setLoadingState(true);
		console.log("rowSelection", rowSelection);
		let datas = []
		rowSelection?.selectedRowKeys?.forEach(e => {
			datas.push({
				id: e,
				paymentStatus: "SUCCESS",
				paymentMethod: "CASH",
				paymentDescription: ""
			})
		});
		console.log("datas", datas);
		const res = await invitationService.updateInvitationCommission({data: datas});
		if(res?.status?.code == 200){
			message.success("Cập nhật thành công!");
			setSelectedRowKeys([]);
			setTotalAmountSelect(0);
			confirmPaymentSuccess?.();
		}
		console.log("res updateInvitationCommission", res);
		setLoadingState(false);

	}



    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

	// const renderStatus = (status) => {
	// 	let objectStatus = getObjectStatus(CONSTANTS.INVITATION_COMMISSION_PAYMENT_STATUS, status);
	// 	return (
	// 		<Tag
	// 			size={'default'}
	// 			color={objectStatus?.color}>
	// 			{texts(objectStatus?.label)}
	// 		</Tag>
	// 	)
	// }

    const columns = [
        {
            title: texts('no.'),
            dataIndex: "stt",
            key: "stt",
            align: "center",
            width: '46px',
        },
        {
            title: "Người nhận tiền",
            dataIndex: "referralAccountName",
            key: "referralAccountName",
	        render: (_, record) =>
		        <a href={getAccountHref(record?.referralAccountId)} target="_blank">{record?.referralAccountName}</a>
//            align: "center",
            // width: '160px',
        },
        {
            title: "Người được giới thiệu",
            dataIndex: "accountName",
            key: "accountName",
	        render: (_, record) =>
		        <a href={getAccountHref(record?.accountId)} target="_blank">{record?.accountName}</a>
//            align: "center",
            // maxWidth: "160px"
        },
        {
            title: "Loại giao dịch",
            dataIndex: "transactionType",
            key: "transactionType",
            align: "center",
        },
        {
            title: "Số tiền giao dịch",
            dataIndex: "transactionAmount",
            key: "transactionAmount",
	        align: "right",
	        render: (_, record) => formatMoneyCurrency(record?.transactionAmount)
        },
	    {
		    title: "% hoa hồng",
		    dataIndex: "commissionPercent",
		    key: "commissionPercent",
		    align: "center",
		    render: (_, record) => (
			    <div>{record.commissionPercent*100}%</div>
		    )
	    },
	    {
		    title: "Số tiền hoa hồng",
		    dataIndex: "commission",
		    key: "commission",
		    align: "right",
		    render: (_, record) => formatMoneyCurrency(record?.commission)
	    },
	    {
		    title: "Thời gian giao dịch",
		    dataIndex: "transactionDate",
		    key: "transactionDate",
		    align: "right",
		    render: (_, record) => record?.transactionDate ? moment(record?.transactionDate).format("HH:mm - DD/MM/YYYY") : "-"
	    },
	    {
		    title: "Trạng thái chi trả",
		    dataIndex: "paymentStatus",
		    key: "paymentStatus",
		    align: "center",
		    render: (_, record) => (
				<div>{renderStatus(record.paymentStatus, texts)}</div>
		    )
	    },
        {
            title: "Hành động",
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (_, record) => (
                <Row gutter={[8, 8]} justify={'center'}>
                    {/*<Col>*/}
                    {/*<Button*/}
                    {/*onClick={() => edit(record)}*/}
                    {/*type='primary'*/}
                    {/*size='small'>*/}
                    {/*Sửa*/}
                    {/*</Button>*/}
                    {/*</Col>*/}
                    <Col>
                        <Button
                            onClick={() => showDetail(record)}
                            ghost
                            type='link'
                            size='small'>
                            {texts('detail')}
                        </Button>
                    </Col>
                </Row>
            ),
        },
    ];

    const expandable = {
        expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.fundFullName}</p>,
        rowExpandable: (record) => record.fundFullName !== 'Not Expandable',
    };

    return (
		<div style={{marginTop: 16}}>

		    <Button type="primary" onClick={() => onConfirmTransferred()} disabled={!hasSelected || loadingState} loading={loading || loadingState}>
			    Xác nhận đã chuyển tiền
		    </Button>

			<span style={{marginLeft: 8, color: "red"}}>{hasSelected ? `Tổng số tiền chi trả: ${formatMoneyCurrency(totalAmountSelect)}` : ''}</span>

	        <Table
	            bordered
	            className="table-data"
	            columns={columns}
	            rowSelection={rowSelection}
	            dataSource={data.map((d, index) => ({
	                ...d,
	                stt: index + 1 + (params.page - 1) * params.size,
	            }))}
	            // expandable={expandable}
	            loading={loading}
	            pagination={false}
	            rowKey={(record) => record?.id}
	            size="small"
	        />
		</div>
    );
};

export default TableData;
