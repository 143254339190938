import request from "../../utils/request";
import request_invest from "../../utils/request_invest";
import ENDPOINT from "../../config/endpoints/invest";

function get_community_info(params) {
    return request({
        url: ENDPOINT.GET_COMMUNITY_INFO,
        method: "get",
        params,
    });
}

function create_community_info(data, params = {}) {
    return request({
        url: ENDPOINT.CREATE_COMMUNITY_INFO,
        method: "post",
        params,
        data
    });
}

function update_community_info(data, params = {}) {
    return request({
        url: ENDPOINT.UPDATE_COMMUNITY_INFO,
        method: "put",
        params,
        data
    });
}

function get_question_info(params) {
    return request({
        url: ENDPOINT.GET_QUESTION_INFO,
        method: "get",
        params,
    });
}

function get_policy(params) {
    return request({
        url: ENDPOINT.GET_MANAGE_INFO,
        method: "get",
        params,
    });
}

function update_policy(data, params = {}) {
    return request({
        url: ENDPOINT.UPDATE_MANAGE_INFO,
        method: "put",
        params,
        data
    });
}

function update_question(data, params = {}) {
    return request({
        url: ENDPOINT.UPDATE_QUESTION_INFO,
        method: "put",
        params,
        data
    });
}

function create_question(data, params = {}) {
    return request({
        url: ENDPOINT.CREATE_QUESTION_INFO,
        method: "post",
        params,
        data
    });
}

function delete_question(data, params = {}) {
    return request({
        url: ENDPOINT.DELETE_QUESTION_INFO,
        method: "delete",
        params,
        data
    });
}

function upload_file(data, params = {}) {
    return request({
        // url: 'http://95.216.124.130:4431/auth/upload-image',
        // url: 'http://95.216.124.130:8082/image-service/auth/upload-image',
        url: ENDPOINT.UPLOAD_FILE,
        method: "post",
        params,
        data
    });
}

function upload_file_other(data, params = {}) {
    return request({
        // url: 'http://95.216.124.130:4431/auth/upload-image',
        // url: 'http://95.216.124.130:8082/image-service/auth/upload-image',
        url: ENDPOINT.UPLOAD_FILE_OTHER,
        method: "post",
        params,
        data
    });
}


function help_center_get() {
    return request({
        url: ENDPOINT.SUPPORT_CENTER_GET,
        method: "get"
    });
}


function help_center_create(data, params = {}) {
    return request({
        url: ENDPOINT.SUPPORT_CENTER_POST,
        method: "post",
        params,
        data
    });
}


function help_center_update(data, params = {}) {
    return request({
        url: ENDPOINT.SUPPORT_CENTER_UPDATE,
        method: "put",
        params,
        data
    });
}

function help_center_delete(id) {
    return request({
        url: ENDPOINT.SUPPORT_CENTER_DELETE,
        method: "put",
        params: {id}
    });
}

//function savings_product_update(data, params = {}) {
//    return request({
//        url: ENDPOINT.SAVINGS_PRODUCT_UPDATE,
//        method: "put",
//        params,
//        data
//    });
//}
//
//function approve_reject_force_settle(data, params = {}) {
//    return request_invest({
//        url: ENDPOINT.APPROVE_REJECT_FORCE_SETTLE,
//        method: "post",
//        params,
//        data
//    });
//}

function get_noti_promotion(params) {
	return request({
		url: ENDPOINT.NOTI_PROMOTION,
		method: "get",
		params
	});
}

function get_noti_promotion_detail(id) {
	return request({
		url: ENDPOINT.NOTI_PROMOTION_DETAIL,
		method: "get",
		params: {id}
	});
}


function create_noti_promotion(data) {
	return request({
		url: ENDPOINT.NOTI_PROMOTION,
		method: "post",
		data
	});
}


function update_noti_promotion(data) {
	return request({
		url: ENDPOINT.NOTI_PROMOTION,
		method: "put",
		data
	});
}


function delete_noti_promotion(id) {
	return request({
		url: ENDPOINT.NOTI_PROMOTION,
		method: "delete",
		params: {id}
	});
}





export default {
    get_community_info,
    create_community_info,
    update_community_info,
    get_policy,
    update_policy,
    get_question_info,
    update_question,
    create_question,
    upload_file,
    upload_file_other,
    delete_question,
    help_center_get,
    help_center_create,
    help_center_update,
    help_center_delete,
	get_noti_promotion,
	create_noti_promotion,
	update_noti_promotion,
	delete_noti_promotion,
	get_noti_promotion_detail
};