// cms/get-log-update-system-config'

import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/invest";

//params = fromDate=1657621392000&toDate=1752315792000&paymentMethod=BANK&paymentStatus=PENDING&transactionType=FMARKET&customerName=tung'
function getSystemConfigApi(params) {
	return request({
		url: ENDPOINT.GET_SYSTEM_CONFIG,
		method: "get",
		params,
	});
}

function updateSystemConfigApi(data) {
	return request({
		url: ENDPOINT.SYSTEM_CONFIG_UPDATE,
		method: "put",
		data
	});
}

function getLogUpdateSystemConfig(params) {
	return request({
		url: ENDPOINT.GET_LOG_UPDATE_SYSTEM_CONFIG,
		method: "get",
		params
	});
}
// "data": [
// 	{
// 		"id": 17,
// 		"paymentStatus": "SUCCESS",
// 		"paymentMethod": "CASH",
// 		"paymentDescription": "paymentDescription 1"
// 	},
// 	{
// 		"id": 27,
// 		"paymentStatus": "SUCCESS",
// 		"paymentMethod": "BANK",
// 		"paymentDescription": "paymentDescription 3"
// 	}
// ]


export default {
	updateSystemConfigApi,
	getSystemConfigApi,
	getLogUpdateSystemConfig
};
