/* 
 * Simple editor component that takes placeholder text as a prop 
 */
import React, {useState, useEffect, useRef} from "react";
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

Quill.register(Quill.import("attributors/style/align"), true);

const modules = {
	toolbar: [
		[{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
		[{size: []}],
		[{ 'align': [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{'list': 'ordered'}, {'list': 'bullet'},
			{'indent': '-1'}, {'indent': '+1'}],
		['link', 'image', 'video'],
		['clean']
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	}
};

const formats = [
	'header', 'font', 'size', 'color',
	'bold', 'italic', 'underline', 'strike', 'blockquote',
	'list', 'bullet', 'indent',
	'link', 'image', 'video',
	'align',
]

const Editor = (props) => {

	const {
		placeholder,
		value,
		setValue = () => {}
	} = props;

	const [editorHtml, setEditorHtml] = useState(value);
	const [theme, setTheme] = useState('snow');

	const reactQuillRef = useRef();

	const handleChange = (html) => {
		setEditorHtml(html);
		setValue(html);
		console.log("html", html);
		// const editor = reactQuillRef.current.getEditor();
		// const htmlNew = editor.getText();
		// const htmlNew = editor.getSemanticHTML();
		// console.log("htmlNew", htmlNew);
	}


	return (
		<div>
			<ReactQuill
				theme={theme}
				onChange={handleChange}
				value={value}
				modules={modules}
				formats={formats}
				// bounds={'.app'}
				placeholder={placeholder}
				ref={reactQuillRef}
			/>
		</div>
	)
}


export default Editor;

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
// Editor.modules = {
// 	toolbar: [
// 		[{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
// 		[{size: []}],
// 		['bold', 'italic', 'underline', 'strike', 'blockquote'],
// 		[{'list': 'ordered'}, {'list': 'bullet'},
// 			{'indent': '-1'}, {'indent': '+1'}],
// 		['link', 'image', 'video'],
// 		['clean']
// 	],
// 	clipboard: {
// 		// toggle to add extra line breaks when pasting HTML:
// 		matchVisual: false,
// 	}
// }
// /*
//  * Quill editor formats
//  * See https://quilljs.com/docs/formats/
//  */
// Editor.formats = [
// 	'header', 'font', 'size',
// 	'bold', 'italic', 'underline', 'strike', 'blockquote',
// 	'list', 'bullet', 'indent',
// 	'link', 'image', 'video'
// ]
//
// /*
//  * PropType validation
//  */
// Editor.propTypes = {
// 	placeholder: PropTypes.string,
// }
//
// /*
//  * Render component on page
//  */
// ReactDOM.render(
// 	<Editor placeholder={'Write something...'}/>,
// 	document.querySelector('.app')
// )