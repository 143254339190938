import {
    DashboardOutlined,
    TeamOutlined,
    FileTextOutlined,
    FileProtectOutlined,
    TagsOutlined,
    DollarOutlined,
    UnorderedListOutlined,
    IdcardOutlined,
    QuestionCircleOutlined,
    GlobalOutlined,
    ExportOutlined,
    ImportOutlined,
    BankOutlined,
    DollarCircleOutlined
} from "@ant-design/icons";
import { Trans } from 'react-i18next';
import { PERMISSIONS } from '../permissions'
import PartnerAmber from "../../pages/invest/partner-amber";

export default {
    ROUTERS: [
        {
            name: "DASHBOARD",
            path: "/",
            element: "Dashboard",
            icon: <DashboardOutlined/>,
            label: <Trans i18nKey='dashboard' />,
            permission_keys: [PERMISSIONS.VIEW_DASHBOARD]
        },
        //khách hàng
        {
            name: "CUSTOMERS",
            path: "/customers",
            element: "Customers",
//            icon: <TeamOutlined/>,
            label: <Trans i18nKey='customers' />,
            menu: "CUSTOMER",
            permission_keys: [PERMISSIONS.GET_LIST_CUSTOMER]
        },
        {
            name: "CUSTOMER_DETAIL",
            path: "/customers/:id",
            element: "CustomerDetail",
//            icon: <TeamOutlined/>,
            label: <Trans i18nKey='customer_info' />,
            parent: "CUSTOMERS",
            permission_keys: [PERMISSIONS.GET_CUSTOMER_INFO]
        },
        {
            name: "EKYC_APPROVE",
            path: "/ekyc-approve",
            element: "EkycApprove",
//            icon: <IdcardOutlined />,
            label: <Trans i18nKey='ekyc_approve' />,
            menu: "CUSTOMER",
            permission_keys: [PERMISSIONS.GET_CUSTOMER_KYC_ERROR]
        },
        {
            name: "EKYC_APPROVE_DETAIL",
            path: "/ekyc-approve/:id",
            element: "EkycApproveDetail",
//            icon: <TeamOutlined/>,
            label: <Trans i18nKey='detail' />,
            parent: "EKYC_APPROVE",
            permission_keys: [PERMISSIONS.GET_CUSTOMER_INFO]
        },

	    {
		    name: "INVITATION_COMMISSIONS",
		    path: "/invitation_commissions",
		    element: "InvitationCommission",
//            icon: <TeamOutlined/>,
		    label: <Trans i18nKey='invitation_commission' />,
		    menu: "CUSTOMER",
		    permission_keys: [PERMISSIONS.GET_CUSTOMER_INFO]
	    },


	    {
		    name: "SYSTEM_CONFIG",
		    path: "/system_config",
		    element: "SystemConfig",
//            icon: <TeamOutlined/>,
		    label: "Cấu hình hệ thống",
		    menu: "SYSTEM_CONFIG",
		    permission_keys: [PERMISSIONS.GET_LIST_CUSTOMER]
	    },
// 	    {
// 		    name: "CONFIG_COMMISSION",
// 		    path: "/config_commissions",
// 		    element: "ConfigCommission",
// //            icon: <TeamOutlined/>,
// 		    label: "Cấu hình hoa hồng",
// 		    menu: "SYSTEM_CONFIG",
// 		    permission_keys: [PERMISSIONS.GET_CUSTOMER_INFO]
// 	    },


        {
            name: "SALESMAN",
            path: "/salesman",
            element: "Salesman",
//            icon: <TeamOutlined/>,
            label: <Trans i18nKey='salesman' />,
            menu: "SALES",
            permission_keys: [PERMISSIONS.GET_LIST_BUSINESS_STAFF]
        },
        {
            name: "SALESMAN_DETAIL",
            path: "/salesman/:id",
            element: "SalesmanDetail",
//            icon: <TeamOutlined/>,
            label: <Trans i18nKey='salesman_info' />,
            parent: "SALESMAN",
            permission_keys: [PERMISSIONS.GET_CUSTOMER_INFO]
        },
        //đầu tư tích luỹ
        {
            name: "SAVINGS_PRODUCTS",
            path: "/product-savings",
            element: "ProductSavings",
//            icon: <TagsOutlined />,
            label: <Trans i18nKey='products' />,
            menu: "SAVINGS",
            permission_keys: [PERMISSIONS.GET_LIST_PRODUCT]
        },
        {
            name: "SAVINGS_TRANSACTIONS",
            path: "/savings-transactions",
            element: "SavingsTransactions",
//            icon: <FileProtectOutlined />,
            label: <Trans i18nKey='transactions' />,
            menu: "SAVINGS",
            permission_keys: [PERMISSIONS.GET_LOG_SAVINGS_PACKAGE]
        },
        {
            name: "SAVINGS_ORDERS",
            path: "/savings-orders",
            element: "SavingsOrders",
//            icon: <FileTextOutlined />,
            label: <Trans i18nKey='orders' />,
            menu: "SAVINGS",
            permission_keys: [PERMISSIONS.GET_LOG_SAVINGS_ORDER]
        },
        {
            name: "SAVING_SETTLE",
            path: "/saving-settle",
            element: "SavingSettle",
//            icon: <DollarOutlined />,
            label: <Trans i18nKey='settlement' />,
            menu: "SAVINGS",
            permission_keys: [PERMISSIONS.LIST_INTERMEDIATE_SETTLED]
        },
//        {
//            name: "SAVINGS_FORCE_SETTLE",
//            path: "/savings-force-settle",
//            element: "SavingsForceSettle",
////            icon: <DollarOutlined />,
//            label: <Trans i18nKey='settlement' />,
//            menu: "SAVINGS",
//            permission_keys: [PERMISSIONS.LIST_INTERMEDIATE_SETTLED]
//        },
        {
            name: "SAVINGS_FORCE_SETTLE_DETAIL",
            path: "/savings-force-settle/:id",
            element: "SavingsForceSettleDetail",
//            icon: <TeamOutlined/>,
            label: <Trans i18nKey='detail' />,
            parent: "SAVINGS_FORCE_SETTLE",
            permission_keys: [PERMISSIONS.GET_FORCE_SETTLE_DETAIL]
        },

        //đầu tư chứng chỉ quỹ
        {
            name: "FMARKET_ACCOUNTS",
            path: "/fmarket-accounts",
            element: "FmarketAccounts",
//            icon: <TeamOutlined />,
            label: <Trans i18nKey='accounts' />,
            menu: "FUNDS",
            permission_keys: [PERMISSIONS.GET_LIST_FMARKET_ACCOUNT]
        },
        {
            name: "FMARKET_ACCOUNT_DETAIL",
            path: "/fmarket-accounts/:id",
            element: "FmarketAccountDetail",
//            icon: <TeamOutlined/>,
            label: <Trans i18nKey='customer_info' />,
            parent: "FMARKET_ACCOUNTS",
            permission_keys: [PERMISSIONS.GET_LIST_FMARKET_ACCOUNT]
            // menu: "CUSTOMER",
        },
        {
            name: "FMARKET_TRANSACTIONS",
            path: "/fmarket-transactions",
            element: "FmarketTransactions",
//            icon: <FileProtectOutlined />,
            label: <Trans i18nKey='transactions' />,
            menu: "FUNDS",
            permission_keys: [PERMISSIONS.GET_LIST_TRANSACTION]
        },
        {
            name: "FMARKET_ORDERS",
            path: "/fmarket-orders",
            element: "FmarketOrders",
//            icon: <FileTextOutlined />,
            label: <Trans i18nKey='orders' />,
            menu: "FUNDS",
            permission_keys: [PERMISSIONS.GET_LIST_ORDER]
        },
        {
            name: "FUNDS_INFO",
            path: "/funds-info",
            element: "FundsInfo",
//            icon: <UnorderedListOutlined />,
            label: <Trans i18nKey='funds_info' />,
            menu: "FUNDS",
            permission_keys: [PERMISSIONS.GET_ALL_MUATUAL_FUNDS_DATA]
        },

        // Đối tác
        {
            name: "PARTNER_FMARKET",
            path: "/partner-fmarket",
            element: "PartnerFmarket",
//            icon: <UnorderedListOutlined />,
            label: 'Fmarket',
            menu: "PARTNER",
            permission_keys: [PERMISSIONS.GET_ALL_MUATUAL_FUNDS_DATA]
        },
        {
            name: "PARTNER_AMBER",
            path: "/partner-amber",
            element: "PartnerAmber",
//            icon: <UnorderedListOutlined />,
            label: 'Amber',
            menu: "PARTNER",
            permission_keys: [PERMISSIONS.GET_ALL_MUATUAL_FUNDS_DATA]
        },



        //giao dich
//        {
//            name: "TRANSACTIONS_TOPUP",
//            path: "/transactions-topup",
//            element: "TransactionsTopupLogs",
////            icon: <ImportOutlined />,
//            label: <Trans i18nKey='topup_logs' />,
//            menu: "TRANSACTIONS",
//            permission_keys: [PERMISSIONS.GET_LOG_BALANCE]
//        },
//        {
//            name: "TRANSACTIONS_WITHDRAW",
//            path: "/transactions-withdraw",
//            element: "TransactionsWithdrawLogs",
////            icon: <ExportOutlined />,
//            label: <Trans i18nKey='withdraw_logs' />,
//            menu: "TRANSACTIONS",
//            permission_keys: [PERMISSIONS.GET_LOG_BALANCE]
//        },
//        {
//            name: "TRANSACTIONS_WITHDRAW_REQUEST",
//            path: "/transactions-withdraw-request",
//            element: "TransactionsWithdrawRequest",
////            icon: <DollarCircleOutlined />,
//            label: <Trans i18nKey='withdraw_request' />,
//            menu: "TRANSACTIONS",
//            permission_keys: [PERMISSIONS.GET_BALANCE_CHANGE_REQUEST]
//        },
        {
            name: "TRANSACTIONS_APPOTA",
            path: "/transactions-appota",
            element: "TransactionsAppotaFirmBanking",
//            icon: <BankOutlined />,
            label: <Trans i18nKey='appota_firm_banking' />,
            menu: "TRANSACTIONS",
            permission_keys: [PERMISSIONS.GET_LOG_CALL_PARTNER]
        },

        //quản trị nội dung
        {
            name: "NEWS",
            path: "/content-news",
            element: "News",
//            icon: <GlobalOutlined />,
            label: <Trans i18nKey='news' />,
            menu: "INFORMATION",
            permission_keys: [PERMISSIONS.GET_COMMUNITY_INFO]
        },
        {
            name: "NEWS_DETAIL",
            path: "/content-news/:id",
            element: "NewsDetail",
//            icon: <TeamOutlined/>,
            label: <Trans i18nKey='news_detail' />,
            parent: "NEWS",
            permission_keys: [PERMISSIONS.GET_COMMUNITY_INFO]
        },
        {
            name: "QUESTION_ANSWER",
            path: "/content-question-answer",
            element: "QuestionAnswer",
//            icon: <QuestionCircleOutlined />,
            label: <Trans i18nKey='question_answer' />,
            menu: "INFORMATION",
            permission_keys: [PERMISSIONS.GET_QUESTION_INFO]
        },
        {
            name: "HELP_CENTER",
            path: "/content-help-center",
            element: "HelpCenter",
//            icon: <QuestionCircleOutlined />,
            label: <Trans i18nKey='help_center' />,
            menu: "INFORMATION",
            permission_keys: [PERMISSIONS.GET_QUESTION_INFO]
        },
        {
            name: "POLICY",
            path: "/content-policy",
            element: "Policy",
//            icon: <FileProtectOutlined />,
            label: <Trans i18nKey='policy' />,
            menu: "LEGAL_CONTRACT",
            permission_keys: [PERMISSIONS.GET_MANAGE_INFO]
        },
	    {
		    name: "NOTIFICATIONS",
		    path: "/content-notification",
		    element: "Notifications",
//            icon: <GlobalOutlined />,
		    label: "Thông báo",
		    menu: "INFORMATION",
		    permission_keys: [PERMISSIONS.GET_COMMUNITY_INFO]
	    },
	    {
		    name: "NOTIFICATION_DETAIL",
		    path: "/content-notification/:id",
		    element: "NotificationDetail",
//            icon: <TeamOutlined/>,
		    label: "Chi tiết thông báo",
		    parent: "NOTIFICATIONS",
		    permission_keys: [PERMISSIONS.GET_COMMUNITY_INFO]
	    },
    ],

};
