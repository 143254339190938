import systemServices from '../../services/system';
import FINBLUE_ROUTERS from './finblue';
import INVEST_ROUTERS from './invest';

import {
  UserOutlined,
  ContactsOutlined,
  TagOutlined,
  SafetyCertificateOutlined
} from "@ant-design/icons";

import { PERMISSIONS } from '../permissions/index'

const getRouters = () => {
  const cmsInfo = systemServices.cms_info();
  let adminMenus = FINBLUE_ROUTERS.ROUTERS;
  switch (cmsInfo?.key) {
    case 'CMS_FINBLUE':
      adminMenus = FINBLUE_ROUTERS.ROUTERS;
      break;
    case 'CMS_INVEST':
      adminMenus = INVEST_ROUTERS.ROUTERS;
    default:
      break;
  }
  return adminMenus;
}

export default {
  AUTH_ROUTERS: [
    {
      name: "LOGIN",
      path: "/auth/login",
      element: "Login",
      not_auth: true,
    },
  ],

  ERROR_ROUTERS: [
    {
      name: "404",
      path: "/errors/404",
      element: "Error404",
    }
  ],

  ADMIN_ROUTERS: [
    {
      name: "403",
      path: "/403",
      element: "Error403",
    },
    {
      name: "CMS_USERS",
      path: "/cms-users",
      element: "CmsUsers",
//      icon: <UserOutlined />,
      label: "Danh sách tài khoản",
      menu: "ADMIN",
      permission_keys: [
        PERMISSIONS.VIEW_LIST_USER_ROLE
      ]
    },
    {
      name: "CMS_USER_CREATE",
      path: "/cms-users/create",
      element: "CmsUserCreate",
//      icon: <UserOutlined />,
      label: "Thêm mới tài khoản quản trị",
      parent: "CMS_USERS",
      permission_keys: [
        PERMISSIONS.CREATE_USER_ROLE,
        PERMISSIONS.VIEW_LIST_ROLE
      ]
    },
    {
      name: "CMS_USER_DETAIL",
      path: "/cms-users/:id/detail",
      element: "CmsUserDetail",
//      icon: <UserOutlined />,
      label: "Chi tiết tài khoản quản trị",
      parent: "CMS_USERS",
      permission_keys: [
        PERMISSIONS.VIEW_USER_ROLE_PERMISSION,
        PERMISSIONS.VIEW_LIST_ROLE
      ]
    },
    {
      name: "ROLES",
      path: "/roles",
      element: "Roles",
//      icon: <ContactsOutlined />,
      label: "Vai trò",
      menu: "ADMIN",
      permission_keys: [
        PERMISSIONS.VIEW_LIST_ROLE
      ]
    },
    {
      name: "ROLE_DETAIL",
      path: "/roles/:id/detail",
      element: "RoleDetail",
      parent: "ROLES",
      label: "Chi tiết vai trò",
      permission_keys: [
        PERMISSIONS.UPDATE_ROLE,
        PERMISSIONS.VIEW_ROLE
      ]
    },
    {
      name: "ROLE_CREATE",
      path: "/roles/create",
      element: "RoleCreate",
      parent: "ROLES",
      label: "Thêm mới vai trò",
      permission_keys: [
        PERMISSIONS.CREATE_ROLE,
        PERMISSIONS.VIEW_LIST_ALL_PERMISSION,
        PERMISSIONS.VIEW_CATEGORY_PERMISSION
      ]
    },
//    {
//      name: "POSITIONS",
//      path: "/positions",
//      element: "Positions",
////      icon: <TagOutlined />,
//      label: "Chức vụ",
//      menu: "ADMIN",
//      permission_keys: [
//        PERMISSIONS.VIEW_LIST_POSITION
//      ]
//    },

   {
     name: "PERMISSIONS",
     path: "/permissions",
     element: "Permissions",
//      icon: <SafetyCertificateOutlined />,
     label: "Quyền truy cập",
     // menu: "ADMIN",
     // permission_keys: [
     //   PERMISSIONS.VIEW_LIST_ALL_PERMISSION,
     //   PERMISSIONS.LIST_ROLE_CATEGORY
     // ]
   },

    {
      name: 'STAFFS',
      path: '/staffs',
      element: 'Staffs',
      label: 'Nhân viên',
    },
    ...getRouters()
  ],
};

export const ROUTE_HIDE_BREADCRUMB = [
  "/"
];
