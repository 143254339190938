export default {
  // Khách hàng
  GET_LIST_CUSTOMER: 'GET_LIST_CUSTOMER',
  GET_FMARKET_ACCOUNT: 'GET_FMARKET_ACCOUNT',
  GET_SAVINGS_CONTRACT: 'GET_SAVINGS_CONTRACT',
  GET_CUSTOMER_KYC_ERROR: 'GET_CUSTOMER_KYC_ERROR',
  GET_CUSTOMER_INFO: 'GET_CUSTOMER_INFO',

  // Nhân viên kinh doanh
  GET_LIST_BUSINESS_STAFF: 'GET_LIST_BUSINESS_STAFF',
  ASIGN_CUSTOMER_BUSINESS_STAFF: 'ASIGN_CUSTOMER_BUSINESS_STAFF',
  GET_LIST_CUSTOMER_BY_STAFF: 'GET_LIST_CUSTOMER_BY_STAFF',

  // Đầu tư tích lũy
  GET_LIST_PRODUCT: 'GET_LIST_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  MULTI_UPDATE_PROCESSING_STATUS_PAYMENT_TRANSACTION: 'MULTI_UPDATE_PROCESSING_STATUS_PAYMENT_TRANSACTION',
  LIST_INTERMEDIATE_SETTLED: 'LIST_INTERMEDIATE_SETTLED',
  GET_FORCE_SETTLE_DETAIL: 'GET_FORCE_SETTLE_DETAIL',
  APPROVE_REJECT_FORCE_SETTLE: 'APPROVE_REJECT_FORCE_SETTLE',

  // Quỹ đầu tư
  GET_LIST_FMARKET_ACCOUNT: 'GET_LIST_FMARKET_ACCOUNT',
  GET_LOG_SAVINGS_PACKAGE: 'GET_LOG_SAVINGS_PACKAGE',
  GET_LOG_SAVINGS_ORDER: 'GET_LOG_SAVINGS_ORDER',
  GET_LIST_TRANSACTION: 'GET_LIST_TRANSACTION',
  GET_LIST_ORDER: 'GET_LIST_ORDER',
  GET_ALL_MUATUAL_FUNDS_DATA: 'GET_ALL_MUATUAL_FUNDS_DATA',
  UPDATE_MUATUAL_FUNDS: 'UPDATE_MUATUAL_FUNDS',

  // Lịch sử giao dịch
  GET_LOG_BALANCE: 'GET_LOG_BALANCE',
  GET_BALANCE_CHANGE_REQUEST: 'GET_BALANCE_CHANGE_REQUEST',
  GET_LOG_CALL_PARTNER: 'GET_LOG_CALL_PARTNER',

  // Quản lý nội dung
  GET_COMMUNITY_INFO: 'GET_COMMUNITY_INFO',
  CREATE_COMMUNITY_INFO: 'CREATE_COMMUNITY_INFO',
  GET_QUESTION_INFO: 'GET_QUESTION_INFO',
  UPDATE_QUESTION_INFO: 'UPDATE_QUESTION_INFO',
  CREATE_QUESTION_INFO: 'CREATE_QUESTION_INFO',
  GET_MANAGE_INFO: 'GET_MANAGE_INFO',
  UPDATE_MANAGE_INFO: 'UPDATE_MANAGE_INFO',

  // Báo cáo tổng hợp
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  GET_CUT_OFF_SAVINGS_ORDER: 'GET_CUT_OFF_SAVINGS_ORDER',
  GET_CUT_OFF_TRANSACTION_FUNDS: 'GET_CUT_OFF_TRANSACTION_FUNDS',
  GET_SUM_AMOUNT_FUND: 'GET_SUM_AMOUNT_FUND',
  GET_CUT_OFF_CUSTOMER: 'GET_CUT_OFF_CUSTOMER',
  TOTAL_AMOUNT_SAVINGS: 'TOTAL_AMOUNT_SAVINGS',
  SUM_CUSTOMER: 'SUM_CUSTOMER',
  SUM_CUSTOMER_FUNDS: 'SUM_CUSTOMER_FUNDS',

	// Cấu hình hệ thống
	SYSTEM_CONFIG_GET: "SYSTEM_CONFIG_GET",
	SYSTEM_CONFIG_UPDATE: "SYSTEM_CONFIG_UPDATE",
	SYSTEM_CONFIG_LOG_GET: "SYSTEM_CONFIG_LOG_GET",

	// hợp đồng, pháp lý
	CONTRACT_LEGA_GET: "CONTRACT_LEGA_GET",
	CONTRACT_LEGA_UPDATE: "CONTRACT_LEGA_UPDATE"
}