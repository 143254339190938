import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints/invest";

//params = fromDate=1657621392000&toDate=1752315792000&paymentMethod=BANK&paymentStatus=PENDING&transactionType=FMARKET&customerName=tung'
function getInvitationCommission(params) {
	return request({
		url: ENDPOINT.INVITATION_COMMISSION,
		method: "get",
		params,
	});
}

function updateInvitationCommission(data) {
	return request({
		url: ENDPOINT.INVITATION_COMMISSION_UPDATE,
		method: "put",
		data
	});
}
// "data": [
// 	{
// 		"id": 17,
// 		"paymentStatus": "SUCCESS",
// 		"paymentMethod": "CASH",
// 		"paymentDescription": "paymentDescription 1"
// 	},
// 	{
// 		"id": 27,
// 		"paymentStatus": "SUCCESS",
// 		"paymentMethod": "BANK",
// 		"paymentDescription": "paymentDescription 3"
// 	}
// ]


export default {
	getInvitationCommission,
	updateInvitationCommission
};
