import React, {useState, useEffect} from "react";
import {AdminHeader} from "../../../components";
import TableData from "./components/TableData";
import {useTranslation} from "react-i18next";
import {Row, Col, Input, Form, Button, message, Space} from "antd";
import {SearchOutlined, PercentageOutlined} from "@ant-design/icons";
import systemConfigService from "../../../services/invest/systemConfigService";

const InvitationCommission = (props) => {
	const {router} = props;
	const [loading, setLoading] = useState(false);
	const [formVisible, setFormVisible] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [historyChange, setHistoryChange] = useState([]);
	const [params, setParams] = useState({});
	const [commissionPercent, setCommissionPercent] = useState();
	// const [fmarketCommissionPercent, setFmarketCommissionPercent] = useState(0);
	// const [savingCommissionPercent, setSavingCommissionPercent] = useState(0);
	// const [insuranceCommissionPercent, setInsuranceCommissionPercent] = useState(0);

	const [form] = Form.useForm();
	const { t } = useTranslation();
	const texts = t;

	useEffect(() => {
		fetchData();
	}, []);

	// useEffect(() => {
	//
	// }, []);

	const handleChangePage = (page, size) => {
		setParams({
			...params,
			page,
			size,
		});
	};

	const fetchData = async () => {
		setLoading(true);
		await getSystemConfig();
		await getLogUpdateSystemConfig();
		setLoading(false);
	};


	const getLogUpdateSystemConfig = async() => {
		const res = await systemConfigService.getLogUpdateSystemConfig();
		if(res?.status?.code == 200){
			setHistoryChange(res?.data || [])
		}
	}


	const getSystemConfig = async () => {
		const res1 = await systemConfigService.getSystemConfigApi();
		const commissionPercentValue = {
			COMMISSION_PERCENT_FMARKET: 100*parseFloat(res1?.data?.find(e => e?.code === "COMMISSION_PERCENT_FMARKET")?.value || "0"),
			COMMISSION_PERCENT_SAVINGS: 100*parseFloat(res1?.data?.find(e => e?.code === "COMMISSION_PERCENT_SAVINGS")?.value || "0"),
			COMMISSION_PERCENT_INSURANCE: 100*parseFloat(res1?.data?.find(e => e?.code === "COMMISSION_PERCENT_INSURANCE")?.value || "0"),
			DAY_COMMISSION: res1?.data?.find(e => e?.code === "DAY_COMMISSION")?.value || "0",
		}
		setCommissionPercent(commissionPercentValue);
		form.setFieldsValue(commissionPercentValue)
	}


	const onFinish = async (values) => {
		// const formValue = form.getFieldsValue();
		// console.log("formValue", formValue);
		// console.log("values", values);
		const {
			COMMISSION_PERCENT_INSURANCE = 0,
			COMMISSION_PERCENT_SAVINGS = 0,
			COMMISSION_PERCENT_FMARKET = 0,
			DAY_COMMISSION = 0
		} = values;

		if(COMMISSION_PERCENT_INSURANCE !== commissionPercent.COMMISSION_PERCENT_INSURANCE){
			const resUpdateInsurance = await systemConfigService.updateSystemConfigApi({
				code: "COMMISSION_PERCENT_INSURANCE",
				value: (COMMISSION_PERCENT_INSURANCE/100).toString()
			});
			if(resUpdateInsurance?.status?.code == 200){
				message.success(`Cập nhật hoa hồng giới thiệu "Bảo hiểm" thành công!`);
			}
			else{
				message.error(`Cập nhật hoa hồng giới thiệu "Bảo hiểm" thất bại!`);
			}
		}

		if(COMMISSION_PERCENT_SAVINGS !== commissionPercent.COMMISSION_PERCENT_SAVINGS){
			const resUpdateSaving = await systemConfigService.updateSystemConfigApi({
				code: "COMMISSION_PERCENT_SAVINGS",
				value: (COMMISSION_PERCENT_SAVINGS/100).toString()
			});
			if(resUpdateSaving?.status?.code == 200){
				message.success(`Cập nhật hoa hồng giới thiệu "Tích luỹ" thành công!`);
			}
			else{
				message.error(`Cập nhật hoa hồng giới thiệu "Tích luỹ" thất bại!`);
			}
		}

		if(COMMISSION_PERCENT_FMARKET !== commissionPercent.COMMISSION_PERCENT_FMARKET){
			const resUpdateFmarket = await systemConfigService.updateSystemConfigApi({
				code: "COMMISSION_PERCENT_FMARKET",
				value: (COMMISSION_PERCENT_FMARKET/100).toString()
			});
			if(resUpdateFmarket?.status?.code == 200){
				message.success(`Cập nhật hoa hồng giới thiệu "Chứng chỉ quỹ" thành công!`);
			}
			else{
				message.error(`Cập nhật hoa hồng giới thiệu "Chứng chỉ quỹ" thất bại!`);
			}
		}

		if(DAY_COMMISSION !== commissionPercent.DAY_COMMISSION){
			const resUpdateFmarket = await systemConfigService.updateSystemConfigApi({
				code: "DAY_COMMISSION",
				value: DAY_COMMISSION
			});
			if(resUpdateFmarket?.status?.code == 200){
				message.success(`Cập nhật số ngày tính hoa hồng thành công!`);
			}
			else{
				message.error(`Cập nhật số ngày tính hoa hồng thất bại!`);
			}
		}

		setCommissionPercent(values);
		await getLogUpdateSystemConfig();
	}

	const handleOpenFormModal = (product = null) => {
		setSelectedProduct(product);
		setFormVisible(true)
	};

	return (
		<div className="cms-users">
			<AdminHeader
				title={router.label}
			/>
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				// onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Row className="" gutter={[8, 8]}>
					<Col span={3} lg={6} sm={12} xs={24}>
						<Form.Item
							label={"Số ngày tính hoa hồng"}
							name={'DAY_COMMISSION'}
							rules={[
								{required: true, message: 'Bắt buộc nhập số ngày'}
							]}>
							<Input
								addonBefore={<PercentageOutlined />}
								placeholder="Nhập số ngày"
								// onChange={(e) => setFmarketCommissionPercent(e)}
							/>
						</Form.Item>
					</Col>
					<Col span={3} lg={6} sm={12} xs={24}>
						<Form.Item
							label={"% hoa hồng Fmarket"}
							name={'COMMISSION_PERCENT_FMARKET'}
							rules={[
								{required: true, message: 'Bắt buộc nhập %'}
							]}>
							<Input
								addonBefore={<PercentageOutlined />}
								placeholder="Nhập %"
								// onChange={(e) => setFmarketCommissionPercent(e)}
							/>
						</Form.Item>
					</Col>
					<Col span={3} lg={6} sm={12} xs={24}>
						<Form.Item
							label={"% hoa hồng Tích luỹ"}
							name={'COMMISSION_PERCENT_SAVINGS'}
							rules={[
								{required: true, message: 'Bắt buộc nhập %'}
							]}>
							<Input
								addonBefore={<PercentageOutlined />}
								placeholder="Nhập %"
								// onChange={(e) => setSavingCommissionPercent(e)}
							/>
						</Form.Item>
					</Col>
					<Col span={3} lg={6} sm={12} xs={24}>
						<Form.Item
							label={"% hoa hồng Bảo hiểm"}
							name={'COMMISSION_PERCENT_INSURANCE'}
							rules={[
								{required: true, message: 'Bắt buộc nhập %'}
							]}>
							<Input
								addonBefore={<PercentageOutlined />}
								placeholder="Nhập %"
								// onChange={(e) => setInsuranceCommissionPercent(e)}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Button type="primary" htmlType="submit">
					Lưu cấu hình
				</Button>
			</Form>


			<div style={{height: 40}}></div>
			<AdminHeader
				title={"Lịch sử thay đổi"}
			/>

			<TableData
				loading={loading}
				data={historyChange}
				params={params}
				// edit={(v) => handleOpenFormModal(v)}
			/>

		</div>
	);
};

export default InvitationCommission;
