import React, {
  useState,
  useEffect,
  useMemo
} from "react";

import {
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Spin,
  Select
} from 'antd';
import { useParams } from 'react-router-dom';

import { AdminDetailHeader } from "../../../components";
import { USERNAME_RULES } from "../../../config/rules";

import PermissionListData from "../permissions/components/ListData";
import roleServices from "../../../services/admin/role";
import userServices from "../../../services/admin/user";

import { PERMISSIONS } from "../../../config/permissions";
import { isPermission } from "../../../utils/common";

const DetailUser = (props) => {
  const { router } = props;
  const { id } = useParams();
  const [form] = Form.useForm();
  const [role, setRole] = useState({});
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [callingAPI, setCallingAPI] = useState(false);

  const roleIds = Form.useWatch('roleIds', form) || [];

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true);
    await fetchUser();
    await fetchRoles();
    setLoading(false)
  }

  const fetchRoles = async (keyword = null) => {
    await roleServices
      .search_roles({
        keyword: keyword,
        page: 0,
        pageSize: 10,
      })
      .then((response) => {
        setRoles(response?.data || []);
      })
      .catch(() => {
        setRoles([]);
      });
  }

  const fetchUser = async () => {
    await userServices
      .get_user(id)
      .then((response) => {
        form.setFieldsValue({
            username: response.data.username,
          roleIds: response.data.roles.map((r) => r.roleId)
        })
        setRole({
          roleCategories: response.data.roleCategories
        });
      })
      .catch(() => {
        setData({});
      });
  }

  const handleUpdateUser = async () => {
    form.validateFields().then(async (values) => {
      setCallingAPI(true)
	    console.log("role?.roleCategories", role?.roleCategories);
      await userServices
        .update_user(id, {
          roleIds: values.roleIds,
          permissionIds: (role?.roleCategories || []).map((c) => c.permissions).flat().filter((p) => p.isChoose && !selectedRolePermissions.includes(p.name)).map((p) => p.permissionId).filter(Boolean)
        })
        .then(() => {
          message.success('Cập nhật tài khoản thành công')
          setCallingAPI(false)
        })
        .catch((error) => {
          setCallingAPI(false)
          message.error(error?.status?.message || 'Lỗi hệ thống. Vui lòng thử lại sau!');
        });
    })
  }

  const selectedRolePermissions = useMemo(() => {
    const selectedRoles = roles.filter((r) => roleIds.includes(r.roleId))
    return selectedRoles.map((r) => r.permission).flat();
  }, [roleIds, roles])

  return (
    <div
      className="role-detail"
    >
      <AdminDetailHeader title={router.label} />
      <Spin spinning={loading}>
        <Form
          form={form}
          layout='vertical'
        >
          <Row gutter={[16, 0]}>
            <Col lg={12} sm={24}>
              <Form.Item
                label="Tên đăng nhập"
                name={'username'}
                rules={USERNAME_RULES}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col lg={24} sm={24}>
              <Form.Item
                label="Vị trí"
                name={'roleIds'}
                rules={[]}
              >
                <Select
                  className="filter-filed"
                  placeholder="Chọn quyền hạn cho tài khoản"
                  mode="multiple"
                  showSearch={true}
                  onSearch={(v) => fetchRoles(v)}
                  optionFilterProp="children"
                  disabled={callingAPI || !isPermission([PERMISSIONS.UPDATE_USER_ROLE])}
                  filterOption={(input, option) =>
                    `${option.children}`.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {roles.map((r) => (
                    <Select.Option value={r.roleId} key={r.roleId}>
                      {r.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={24} sm={24}>
              <Form.Item
                label="Quyền truy cập đặc biệt"
                name={'permissionIds'}
                rules={[]}
              >
                {
                  !loading && <PermissionListData
                    role={role}
                    rolePermissions={selectedRolePermissions}
                    setRole={setRole}
                    disabled={callingAPI || !isPermission([PERMISSIONS.UPDATE_USER_ROLE])}
                  />
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {
          isPermission([PERMISSIONS.UPDATE_USER_ROLE]) && <Row gutter={[8, 8]} className="mt-0">
            <Col lg={12} align="right">
              <Button
                size="large"
                className="w-30"
                disabled={callingAPI}
                onClick={() => window.navigatePage('BACK')}
              >
                Huỷ
              </Button>
            </Col>
            <Col lg={12}>
              <Button
                size="large"
                type="primary"
                loading={callingAPI}
                className="w-30"
                onClick={() => handleUpdateUser()}
              >
                Lưu
              </Button>
            </Col>
          </Row>
        }
      </Spin>
    </div>
  );
};

export default DetailUser;
