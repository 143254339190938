
import CONSTANTS from "../config/constants";
import CONSTANTS_INVEST from "../config/constants_invest";
import moment from 'moment';
import {Tag} from "antd";
import React from "react";

export const getFmkTransactionTypeColorByStatus = (status) => {
    return CONSTANTS.FMARKET_TRANSACTION_STATUS.find(e => e.value === status)?.color || 'green';
};

export const getSavingsProductStatus = (status) => {
    return CONSTANTS.SAVINGS_PRODUCT_ACTIVE_STATUS.find(e => e.value === status) || {};
};

export const getObjectStatus = (array, status) => {
    return array?.find(e => e.value === status) || {};
};

export const displayInvestProfitCapitalDeal = (profit_capital_deal) => {
    console.log('profit_capital_deal', profit_capital_deal);
    return CONSTANTS.INVEST_PROFIT_CAPITAL_DEAL.find((e) => e.value == profit_capital_deal)?.name;
};

export const getAccountHref = (customer_id) => {
    return `${window.location.origin}/customers/${customer_id}`;
};

export const genRequestId = () => {
    return moment().format('YYYYMMDDHHmmss') + (Math.floor(Math.random()*89999 ) + 10000).toString();
}

export const removeNullUndefined = (obj, filter = [null, undefined]) => {
	filter.forEach(e => {
		for (const key in obj) {
			if (obj[key] === e) {
				delete obj[key];
			}
		}
	})
	// if(filter.includes("")){
	//
	// }
	// for (const key in obj) {
	// 	if (obj[key] === null || obj[key] === undefined) {
	// 		delete obj[key];
	// 	}
	// }
	return obj;
}

export const renderStatus = (status, texts) => {
	let objectStatus = getObjectStatus(CONSTANTS_INVEST.INVITATION_COMMISSION_PAYMENT_STATUS, status);
	return (
		<Tag
			size={'default'}
			color={objectStatus?.color}>
			{texts(objectStatus?.label)}
		</Tag>
	)
}